//
//
//
//
//
//
//
//
//
//
//
//
//

import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters } from 'vuex';

export default {
  name: 'app-settings-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },
  },
};
